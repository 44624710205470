import React from 'react'
import { graphql } from 'gatsby'
import CollectionHeroSection from '../components/collection/CollectionHeroSection'
import CollectionMediaSection from '../components/collection/CollectionMediaSection'
import CollectionGridSection from '../components/collection/CollectionGridSection'
import SEO from '../components/shared/SEO'
import {useGlobalComponentsQuery} from '../hooks/index'

export interface collectionProps {
  data: {
    contentfulPageCollection: {
      title: string
      seoTitle: string
      seoDescription: string
      heroImage: {
        fluid: string
      }
      heroTopText: string
      heroBottomText: string
      mediaSection: {
        title: string
        subtitle: string
        paragraph: string
        video: any
        videoVimeo: string
        videoTitle: string
      }
      items: {
        title: string
        slug: string
        shopifyData: {
          variants: {
            price: string
            compare_at_price: string
            inventory_quantity:string
          }
        }
        productTag: string
        thumbnailImages: {
          fluid: string
        }
      }
    }
  }
}

const Collection = ({ data: { contentfulPageCollection } }: collectionProps) => {

  const {
    seoTitle,
    seoDescription,
    heroImage,
    heroTopText,
    heroBottomText,
    mediaSection,
    items,
  } = contentfulPageCollection

  const global = useGlobalComponentsQuery()

  return (
    <>
      <SEO
        title={seoTitle}
        description={seoDescription}
        image={global?.seoImage.file.url}
      />
      <CollectionHeroSection
        heroImage={heroImage}
        heroTopText={heroTopText}
        heroBottomText={heroBottomText}
      />
      {mediaSection && <CollectionMediaSection mediaSection={mediaSection} />}
      <CollectionGridSection items={items} />
    </>
  );
}

export default Collection

export const CollectionPageQuery = graphql`
  query CollectionPage($id: String!) {
    contentfulPageCollection(id: { eq: $id }) {
      title
      seoTitle
      seoDescription
      heroImage {
        fluid(maxWidth: 1920, quality: 50) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      heroTopText
      heroBottomText
      mediaSection {
        title
        heading
        subtitle {
          subtitle
        }
        paragraph {
          paragraph
        }
        invertedLayout
        borderlessImage
        video{
          title
          file {
            url
          }
        }
        videoVimeo
        videoTitle
      }
      items {
        title
        titleToShow
        slug
        shopifyData {
          variants {
            price
            compare_at_price
            inventory_quantity
            sku
          }
        }
        productTag
        thumbnailImages {
          fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`