/* eslint-disable  */

/* eslint-disable-line no-console  */
/* eslint-disable no-console */

import Img from 'gatsby-image';
import React, { useContext } from 'react';
import { useInView } from 'react-intersection-observer';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';
import { useGlobalLayoutQuery } from '../../hooks/index';
import { colors, desktopBreakpoint, desktopVW, mobileVW } from '../../styles/index';
import formatPrice from '../../utils/formatPrice';
import LinkTool from '../shared/LinkTool';
import { SettingsContext } from '../../contexts';

interface Props {
  id: string;
  title: string;
  slug: string;
  sku: string;
  stock: boolean;
  price: string;
  thumbnailImages: {
    fluid: string;
  };
  invertedImages: boolean;
  isFullScreen?: boolean;
  productTag: string;
  compareAtPrice: string;
  isMobileGrid: string;
  isBackgroundTransparent?: boolean;
  index: number;
  pageAllProduct: boolean;
}

export default function ProductThumbnail(props: Props) {
  const {
    title,
    slug,
    price,
    stock,
    thumbnailImages,
    invertedImages,
    isFullScreen,
    productTag,
    compareAtPrice,
    isMobileGrid,
    isBackgroundTransparent,
  } = props;

  const layout = useGlobalLayoutQuery();
  const colorSale = layout?.colorPriceSale;

  const {
    allProductShopify,
  }: {
    allProductShopify: any;
  } = useContext(SettingsContext);

  const [imgSpring, setImgSpring] = useSpring(() => ({
    transform: 'translateX(-100%)',
    config: {
      tension: 152,
      friction: 31,
    },
  }));

  const [thumbnail, thumbnailInView] = useInView({
    threshold: 0,
  });

  const handleMouseEnter = () => {
    const isClient = typeof window === 'object';
    if (!isClient) return false;
    if (window.innerWidth >= 1024) {
      setImgSpring({ transform: 'translateX(0%)' });
    }
  };

  const handleMouseLeave = () => {
    const isClient = typeof window === 'object';
    if (!isClient) return false;
    if (window.innerWidth >= 1024) {
      setImgSpring({ transform: 'translateX(-100%)' });
    }
  };

  function checkQuantity(slug: string) {
    const checkProduct = allProductShopify?.filter(item => item?.node?.handle === slug);
    if (checkProduct?.length > 0) {
      let checkAvailable = checkProduct[0]?.node?.variants?.edges?.filter(
        item => item?.node?.quantityAvailable > 0
      );
      if (checkAvailable?.length > 0) {
        return 1;
      }
    }
    return 0;
  }

  return (
    <Wrapper
      onMouseOver={() => handleMouseEnter()}
      onMouseLeave={() => handleMouseLeave()}
      ref={thumbnail}
    >
      <Link to={`/${slug}/`}>
        <ImagesBlock invertedImages={invertedImages} isFullScreen={isFullScreen}>
          <ImgCropper style={{ ...imgSpring }}>
            {thumbnailInView && <StyledImg fluid={thumbnailImages[0].fluid} loading="eager" />}
          </ImgCropper>
          <ImgCropper style={{ ...imgSpring }}>
            {thumbnailInView && thumbnailImages[1]?.fluid && (
              <StyledImg fluid={thumbnailImages[1].fluid} loading="eager" />
            )}
          </ImgCropper>
        </ImagesBlock>
        <TextBlock
          isMobileGrid={isMobileGrid}
          isFullScreen={isFullScreen}
          isBackgroundTransparent={isBackgroundTransparent}
        >
          <p className="thumbnailTitle">{title}</p>
          <div className="priceRow">
            {Number(compareAtPrice) > Number(price) ? (
              <ProductPrice style={{ color: colorSale }}>{formatPrice(price, 'Rp')}</ProductPrice>
            ) : (
              <ProductPrice>{formatPrice(price, 'Rp')}</ProductPrice>
            )}

            {Number(compareAtPrice) > Number(price) && (
              <ProductPrice isDiscount="potato">
                {formatPrice(compareAtPrice, 'Rp')}
                <div className="strikeTrough" />
              </ProductPrice>
            )}
          </div>
        </TextBlock>
      </Link>
      <RibbonWrapper>
        {productTag && productTag.toLowerCase() !== 'no' && (
          <TagRibbon>
            <p>{productTag}</p>
          </TagRibbon>
        )}
        {checkQuantity(slug) > 0 ? (
          <TagRibbon>
            <p>In Stock</p>
          </TagRibbon>
        ) : null}
        {compareAtPrice &&
          Number(compareAtPrice) > Number(price) &&
          productTag &&
          productTag.toLowerCase() === 'no' && (
            <SaleRibbon>
              <p>sale</p>
            </SaleRibbon>
          )}
      </RibbonWrapper>
    </Wrapper>
  );
}

const Link = styled(LinkTool)`
  width: 100%;
  height: 100%;

  ${desktopBreakpoint} {
  }
`;

const Wrapper = styled.article`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  ${desktopBreakpoint} {
  }
`;

const ImagesBlock = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: ${({ invertedImages }) => (invertedImages ? 'row-reverse' : 'row')};
  width: 200%;
  height: ${({ isFullScreen }) => (isFullScreen ? '88%' : '72.2%')};

  ${desktopBreakpoint} {
    height: 89%;
  }
`;

const TextBlock = styled.div`
  width: 100%;  
  font-family: "messinaMono", "Courier New", Courier, monospace;
  letter-spacing: -0.05em;
  padding-top: ${mobileVW(5)};
  padding-bottom: 0;
  //height: ${mobileVW(80)};
  height: ${({ isFullScreen }) => (isFullScreen ? 'auto' : `${mobileVW(80)}`)};
  ${({ isMobileGrid }) => (isMobileGrid ? 'position: absolute;' : null)}
  bottom: 0;
  left: 0;
  background-color: ${({ isBackgroundTransparent }) =>
    isBackgroundTransparent ? 'transparent' : colors.frappuccino};

  .priceRow{
    display: flex;
    flex-direction: row;

  }

  p{
    color: ${colors.black};
    line-height: 150%;
    font-size: ${mobileVW(15)};
    font-family: 'messinaMonoLight', "Courier New", Courier, monospace;
    letter-spacing: -0.05em;
    ${desktopBreakpoint}{
      font-size: ${desktopVW(14)}; 
      padding-top: 0;
    }
  }


  ${desktopBreakpoint}{
    padding-top: ${desktopVW(10)};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 0;
    height: auto;
  }
`;

const ProductPrice = styled.div`
  position: relative;
  display: inline;
  font-family: 'messinaMonoLight', 'Courier New', Courier, monospace;
  font-weight: 300;
  line-height: 150%;
  letter-spacing: -0.05em;
  color: ${colors.subtleGrey};
  font-size: ${mobileVW(15)};
  font-weight: 300;
  color: ${colors.subtleGrey};
  padding: ${mobileVW(5)} ${mobileVW(10)} ${mobileVW(10)} 0;
  ${({ isDiscount }) => (isDiscount ? 'opacity: 0.5;' : null)}

  .strikeTrough {
    width: 90%;
    height: ${mobileVW(1)};
    position: absolute;
    left: 0;
    top: 45%;
    transform: translate(-3%, -50%);
    background-color: ${colors.subtleGrey};

    ${desktopBreakpoint} {
      height: ${desktopVW(1)};
      top: 49%;
    }
  }

  ${desktopBreakpoint} {
    justify-content: flex-start;
    font-size: ${desktopVW(15)};
    top: 50%;
    padding: ${desktopVW(5)} ${desktopVW(7)} ${desktopVW(5)} 0;
  }
`;

const StyledImg = styled(Img)`
  height: 100%;
  width: auto;

  ${desktopBreakpoint} {
  }
`;

const ImgCropper = styled(animated.div)`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: ${colors.noItWasNotTheLastBeigeNouance};

  ${desktopBreakpoint} {
  }
`;

const RibbonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  top: ${mobileVW(10)};
  right: ${mobileVW(10)};

  ${desktopBreakpoint} {
    top: ${desktopVW(20)};
    right: ${desktopVW(20)};
  }
`;

const SaleRibbon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: ${desktopVW(20)};

  p {
    font-family: 'messina', Arial, Helvetica, sans-serif;
    font-size: ${mobileVW(12)};
    font-weight: 300;
    text-transform: uppercase;
    color: ${colors.cappuccino};
    background-color: ${colors.black};
    padding: ${mobileVW(5)};

    ${desktopBreakpoint} {
      font-size: ${desktopVW(10)};
      padding: ${desktopVW(5)};
    }
  }

  ${desktopBreakpoint} {
    justify-content: flex-start;
    padding-bottom: ${desktopVW(5)};
  }
`;

const TagRibbon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: ${desktopVW(20)};

  p {
    font-family: 'messina', Arial, Helvetica, sans-serif;
    font-size: ${mobileVW(12)};
    font-weight: 300;
    text-transform: uppercase;
    color: ${colors.black};
    background-color: ${colors.frappuccino};
    padding: ${mobileVW(5)};

    ${desktopBreakpoint} {
      font-size: ${desktopVW(10)};
      padding: ${desktopVW(5)};
    }
  }

  ${desktopBreakpoint} {
    justify-content: flex-start;
    padding-bottom: ${desktopVW(5)};
  }
`;
