import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { desktopBreakpoint, colors, desktopVW, mobileVW, mobileContentSidePadding, zIndex } from '../../styles/index'
import Img from 'gatsby-image'
import Icons from '../../utils/Icons'


interface Props {
  mediaSection : {
    title: string
    heading?: string
    subtitle?: string
    paragraph?: string
    video: any
    videoVimeo: string
    videoTitle: string
    image: string
  }
}

export default function CollectionMediaSection(props: Props) {

  const {
    mediaSection,
  } = props

  const [videoTime, setVideoTime] = useState(0)
  const [isButtonPlay, setIsButtonPlay] = useState(false)
  const videoRef = useRef()

  const handlePlaybutton = ()=>{
    if(!videoRef) return false
    setIsButtonPlay(!isButtonPlay)
    if(videoRef.current.paused){
      videoRef.current.play()
    } else {
      videoRef.current.pause()
    }
  }

  function secondsToHms(secondsInput: number) {
    const pad = (n: any) => (n < 10) ? ("0" + n) : n
    let minutes = pad(Math.floor(secondsInput % 3600 / 60) )
    let seconds = pad(Math.floor(secondsInput % 3600 % 60) )
    return `${minutes}:${seconds}`
  }

  return (
    <Wrapper>
      <Text>
        <TextContent>
          {mediaSection.heading &&
            <h5>
              {mediaSection.heading}
            </h5>
          }
          {mediaSection.subtitle &&
            <h6>
              {mediaSection.subtitle.subtitle}
            </h6>
          }
          {mediaSection.paragraph &&
            <p>
            {mediaSection.paragraph.paragraph}
            </p>
          }
        </TextContent>
      </Text>
      <RelativeWrapper>
        {
          videoRef.current
          &&
          <VideocontrolWrapper>
            <PlayButton 
              onClick={()=>handlePlaybutton()}
              aria-label={isButtonPlay ? 'play video' : 'pause video'}
              type='button'
            >
              <Icons
                name={isButtonPlay ? 'play' : 'pause'}
              />
            </PlayButton>
            <VideoTextWrapper>
              <VideoText>
                {mediaSection.videoTitle ? mediaSection.videoTitle : mediaSection.video.title.replace("(Vimeo)", "")}
              </VideoText>
              <VideoText>
                {`${secondsToHms(videoTime)} / ${secondsToHms(videoRef.current.duration)}`}
              </VideoText>
            </VideoTextWrapper>
          </VideocontrolWrapper>
        }
        {
          mediaSection.image
          ?
          <MediaCropper>
            <StyledImage
            fluid={mediaSection.image.fluid} 
            />
          </MediaCropper>
          :
          <MediaCropper>
            <VideoPlayer
            controls={false}
            autoPlay
            disableRemotePlayback
            loop
            playsInline
            muted
            preload="meta"
            ref={videoRef}
            onTimeUpdate={e => setVideoTime(Math.round(videoRef.current.currentTime))}
            >
              <source src={`${mediaSection.videoVimeo ? mediaSection.videoVimeo : mediaSection.video.file.url}`} type="video/mp4" />
            </VideoPlayer>
          </MediaCropper>
        }
      </RelativeWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${desktopBreakpoint}{
    flex-direction: row;
    padding: ${desktopVW(78)} ${desktopVW(10)} ${desktopVW(58)} ${desktopVW(0)};
  }
`;

const Text = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  z-index: ${zIndex.surface};
  display: flex;
  justify-content: center;
  padding: ${mobileContentSidePadding} ${mobileContentSidePadding};

  ${desktopBreakpoint}{
    width: 33.3vw;
    height: auto;
    padding: ${desktopVW(1)} 0 ${desktopVW(45)} 0;
  }
`;

const TextContent = styled.div`
  position: relative;

  h5{
    font-family: "messinaMono";
    letter-spacing: -0.05em;
    font-size: ${mobileVW(27)};
    font-weight: 400;

    ${desktopBreakpoint}{
      font-size: ${desktopVW(30)};  
    }
  }

  h6{
    font-family: "messinaMonoLight";
    letter-spacing: -0.05em;
    text-decoration: underline;
    font-weight: 300;
    font-size: ${mobileVW(23)};
    padding: ${mobileVW(30)} 0 0 0 ;


    ${desktopBreakpoint}{
      font-size: ${desktopVW(20)};  
      padding: ${desktopVW(20)} 0 0 ${desktopVW(3)};
      width: 80%;
      line-height: 140%;
    }
  }

  p{
    font-family: "messinaMonoLight";
    letter-spacing: -0.05em;
    font-weight: 300;
    color: ${colors.subtleGrey};
    padding: ${mobileVW(30)} 0 0 0;
    width: 75%;
    font-size: ${mobileVW(16)};

    ${desktopBreakpoint}{
      font-size: ${desktopVW(14.5)};  
      padding: ${desktopVW(23)} 0 0 ${desktopVW(3)};
      width: 69%;
      line-height: ${desktopVW(21)};
    }
  }

  ${desktopBreakpoint}{
    width: 76%;
  }
`;

const VideoPlayer = styled.video`
  object-fit: cover;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: ${mobileVW(450)};
  z-index: ${zIndex.background};
  &::-webkit-media-controls {
    display: block;
    -webkit-appearance: block;
  }

  ${desktopBreakpoint}{
    //position: absolute;
    height: 100%;
    width: 100%;
    left: auto;
    top: 0;
    right: 0;
  }
`

const MediaCropper = styled.div`
  height: ${mobileVW(450)};
  width: 100%;
  overflow: hidden;

  ${desktopBreakpoint}{
    width: 62.5vw;
    height: ${desktopVW(650)};
  }
`;

const StyledImage = styled(Img)`
  width: 100%;
  z-index: ${zIndex.background};

`;

const VideocontrolWrapper = styled.div`
  position: absolute;
  left: ${mobileVW(5)};
  bottom: ${mobileVW(10)};
  z-index: ${zIndex.surface};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${desktopBreakpoint}{
    left: ${desktopVW(50)};
    bottom: ${desktopVW(50)};

  }
`;

const PlayButton = styled.button`
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${mobileVW(60)};
  height: ${mobileVW(60)};

  ${desktopBreakpoint}{
    width: ${desktopVW(60)};
    height: ${desktopVW(60)};
  }
`;

const VideoText = styled.div`
  font-family: 'messinaMono';
  letter-spacing: -0.05em;
  font-size: ${mobileVW(16)};
  color: ${colors.white};

  ${desktopBreakpoint}{
    font-size: ${desktopVW(13)};
  }
`;

const VideoTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  padding-left: ${mobileVW(20)};
  ${desktopBreakpoint}{
    padding-left: ${desktopVW(20)}; 
  }
`;

const RelativeWrapper = styled.div`
  position: relative;

`;