import React from 'react'
import styled from 'styled-components'
import { desktopBreakpoint, desktopVW, mobileVW, zIndex } from '../../styles/index'
import ProductThumbnail from '../shared/ProductThumbnail'


interface Props {
  items : {
    title: string
    slug: string
    shopifyData: {
      variants: {
        price: string
        compare_at_price: string
      }
    }
    productTag: string
    thumbnailImages: {
      fluid: string
    }
  }
}

export default function CollectionGridSection(props: Props) {

  const {
    items,
  } = props
console.log('items',items)
  return (
    <GridWrapper>
      {items?.map((item, index) => (
        <ThumbnailWrapper key={item.title + index}>
          <ProductThumbnail
            index={index}
            sku={item.shopifyData?.variants[0]?.sku}
            thumbnailImages={item.thumbnailImages}
            title={item.titleToShow}
            invertedImages={index === 2}
            price={item.shopifyData.variants[0].price}
            compareAtPrice={item.shopifyData.variants[0].compare_at_price}
            slug={item.slug}
            productTag={item.productTag}
            isFullScreen={index === 2}
            isMobileGrid="potato"
          />
        </ThumbnailWrapper>
      ))}
    </GridWrapper>
  )
}

const GridWrapper = styled.div`
  display: grid;
  padding: ${mobileVW(35)} ${mobileVW(20)} 0 ${mobileVW(20)};
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: ${mobileVW(20)};
  z-index: ${zIndex.surface};

  ${desktopBreakpoint}{
    grid-template-columns: repeat(4, ${desktopVW(310)});
    grid-template-rows: ${desktopVW(455)};
    grid-gap: ${desktopVW(26)};
    padding: 0 ${desktopVW(60)};
  }
`;

const ThumbnailWrapper = styled.div`
  height: ${mobileVW(310)};
  ${desktopBreakpoint}{
    height: ${desktopVW(455)};
  }
  &:nth-child(3) {
    grid-column: span 2;
    height: ${mobileVW(580)};
    ${desktopBreakpoint}{
      height: auto;
    }
  }
`;